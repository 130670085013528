<template>
  <div>
    <div :class="headingClassesComputed">
      <div v-if="headingType === 'h2' || isH2">
        <div class="d-flex align-items-center">
          <h2
            v-if="!disabledToc"
            data-toc-item="true"
            data-toc-level="2"
            :data-toc-title="heading"
          >{{heading}}</h2>
          <div v-else class="h2">{{heading}}</div>
          <div v-if="hasExtraInfo" class="ml-auto heading__extra-info">
            {{extraInfo}}
          </div>
        </div>
        <h3 v-if="hasSubtitle">{{subtitle}}</h3>
      </div>
      <div v-else-if="headingType === 'h3' || isH3">
        <div class="d-flex align-items-center">
          <h3
            data-toc-item="true"
            data-toc-level="3"
            :data-toc-title="heading"
          >{{heading}}</h3>
          <div v-if="hasExtraInfo" class="ml-auto heading__extra-info">
            {{extraInfo}}
          </div>
        </div>
        <h4 v-if="hasSubtitle">{{subtitle}}</h4>
      </div>
      <div v-else-if="headingType === 'h4' || isH4">
        <div class="d-flex align-items-center">
          <h4
            data-toc-item="true"
            data-toc-level="4"
            :data-toc-title="heading"
          >{{heading}}</h4>
          <div v-if="hasExtraInfo" class="ml-auto heading__extra-info">
            {{extraInfo}}
          </div>
        </div>
        <h5 v-if="hasSubtitle">{{subtitle}}</h5>
      </div>
      <div v-else-if="headingType === 'h5' || isH5">
        <div class="d-flex align-items-center">
          <h5
            data-toc-item="true"
            data-toc-level="5"
            :data-toc-title="heading"
          >{{heading}}</h5>
          <div v-if="hasExtraInfo" class="ml-auto heading__extra-info">
            {{extraInfo}}
          </div>
        </div>
        <h6 v-if="hasSubtitle">{{subtitle}}</h6>
      </div>
      <div v-else-if="headingType === 'h6' || isH6">
        <div class="d-flex align-items-center">
          <h6
            data-toc-item="true"
            data-toc-level="6"
            :data-toc-title="heading"
          >{{heading}}</h6>
          <div v-if="hasExtraInfo" class="ml-auto heading__extra-info">
            {{extraInfo}}
          </div>
        </div>
      </div>
      <div v-else>
        <div class="d-flex align-items-center">
          <h1
            data-toc-item="true"
            data-toc-level="1"
            :data-toc-title="heading"
          >{{heading}}</h1>
          <div v-if="hasExtraInfo" class="ml-auto heading__extra-info">
            {{extraInfo}}
          </div>
        </div>
        <h2 v-if="hasSubtitle">{{subtitle}}</h2>
      </div>
    </div>
  </div>
</template>

<script>
import { isPresent } from '@/utils/validators';

export default {
  name: 'Heading',
  props: {
    headingType: String,
    isH1: Boolean,
    isH2: Boolean,
    isH3: Boolean,
    isH4: Boolean,
    isH5: Boolean,
    isH6: Boolean,
    headingClasses: String,
    heading: String,
    subtitle: String,
    extraInfo: String,
    disabledToc: Boolean,
  },
  computed: {
    headingClassesComputed() {
      const headingClasses = this.headingClasses ? this.headingClasses : '';
      return `heading heading--is-${this.headingType} ${headingClasses}`;
    },
    hasSubtitle() {
      return !!isPresent(this.subtitle);
    },
    hasExtraInfo() {
      return !!isPresent(this.extraInfo);
    },
  },
};
</script>

<style scoped lang="scss">
//heading
.heading{
  margin-bottom: 1rem;

  + .heading{
    margin-top: 2rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6{
    &:last-child{
      margin-bottom: 0;
    }
  }

  &.heading--is-h1,
  &.heading--is-h2,
  &.heading--is-h3{
    color: $white;
    padding: .35rem .75rem;
  }
  &.heading--is-h1{
    background-color: $gray-600;
    border: 1px solid $gray-600;
  }
  &.heading--is-h2{
    background-color: $gray-500;
  }
  &.heading--is-h3{
    background-color: $gray-400;
  }
}
.heading__extra-info{
  color: $white;
  font-size: .7rem;
}

//subheading
.subheading{
  color: $accent-color--darkened;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  padding-bottom: .5rem;
  position: relative;

  .subheading__icon{
    color: $accent-color;
  }

  .card-title:last-child &{
    margin-bottom: 0;
  }
  &::after{
    background-color: $accent-color-alt;
    content: '';
    height: 5px;
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    width: 3rem;
  }

  &.subheading--alt-style{
    &::after{
      background-color: $accent-color;
    }
  }
}

</style>
