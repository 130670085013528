<template>
  <heading
    :heading="heading"
    :subtitle="subtitle"
    heading-type="h2"
    :headingClasses="headingClasses"
    :extra-info="extraInfo"
    :disabled-toc="disabledToc"
  />
</template>

<script>

import Heading from '@/components/clickCard/Heading.vue';

export default {
  name: 'Heading2',
  components: { Heading },
  props: {
    headingClasses: String,
    heading: String,
    subtitle: String,
    extraInfo: String,
    disabledToc: Boolean,
  },
};
</script>

<style scoped>

</style>
